<template>
  <div>
    <div class="top-bar flex flex-col justify-between">
      <div class="account-title">Transaction</div>
      <div class="nav-bar ">
        <div class="bar-list is_active ">对账单</div>
      </div>
    </div>
    <div class="transition-bot">
      <div class="flex">
        <div class="transition-bot-left mx-auto sm:ml-0">
          <!-- <div class="font14">
            要存入资金的账户：
            <BaseSelectionAccount></BaseSelectionAccount>
          </div> -->
          <div class="font14">
            日期范围：
            <div>
            </div>
          </div>
        </div>
        <!-- <div class="transition-bot-right">
          <div class="account_num">账户汇总</div>
          <div class="flexBetween alignCenter marginTop20 black45">
            <div>
              总余额:
              <img
                class="questionIcon"
                src="@/assets/icon/question.png"
                alt=""
              />
            </div>
            <div>0.00 NZD</div>
          </div>
          <div class="flexBetween alignCenter marginTop16 black45">
            <div>
              预留余额:
              <img
                class="questionIcon"
                src="@/assets/icon/question.png"
                alt=""
              />
            </div>
            <div>0.00 NZD</div>
          </div>
          <div class="flexBetween alignCenter marginTop16 weight600 black45">
            <div>
              可用余额:
              <img
                class="questionIcon"
                src="@/assets/icon/question.png"
                alt=""
              />
            </div>
            <div class="black85">0.00 NZD</div>
          </div>
        </div> -->
      </div>
      <div class="transition-bold-title mt-16px">搜索选项</div>
      <div class="search-option marginTop20 flex">
        <span class="font14 marginRight10">仅显示: </span>
      </div>
      <div class="search-option marginTop20 flex">
        <span class="font14 marginRight10">仅显示: </span>
        <!-- <div class="flex items-center">
          <label class=" pl-35px relative cursor-pointer flex items-center mr-6" for="transaction">
            <input
            class=" opacity-0 absolute left-0"
            type="radio"
            name="selectType"
            checked
            id="transaction">
            <span class="m pl-4 text-14px font-500 disable ">Transaction</span>
          </label>
          <label class=" pl-35px relative flex cursor-pointer items-center mr-6" for="deposite">
            <input
            class=" opacity-0 absolute left-0"
            type="radio"
            name="selectType"
            id="deposite">
            <span class="m pl-4 text-14px font-500 disable">Deposite</span>
          </label>
          <label class=" pl-35px relative cursor-pointer flex items-center mr-6" for="withdrawal">
            <input
            class=" opacity-0 absolute left-0"
            type="radio"
            name="selectType"
            id="withdrawal">
            <span class="m pl-4 text-14px font-500 disable">Withdrawal</span>
          </label>
        </div> -->
      </div>
      <div class="marginTop20">
      </div>
    </div>
  </div>
</template>

<script>
// import BaseSelectionAccount from '@/components/User/BaseSelectionAccount.vue'

export default {

  components: {
    // BaseSelectionAccount
  },
  data () {
    return {
    }
  }
}

</script>

<style scoped>
.transition-bot {
  padding: 32px 0 0 24px;
}

.account_num {
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}
.questionIcon {
  width: 16px;
  height: 16px;
}
.transition-bold-title {
  font-size: 18px;
  font-weight: 400;
  color: #bc9a41;
  line-height: 24px;
  text-decoration: underline;
}
.cbxStyle {
  background: red;
  appearance: none;
}
.el-Column{
  color: red;
}
.disable{
  color: #C0C4CC;
}
.input-bg{
  background: #edf2fc;
}
/* .search-input {
  width: 379px;
  height: 40px;
  background: #ffffff url("~@/assets/icon/search.png") no-repeat 355px 12px/16px
    16px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  box-sizing: border-box;
} */
@media only screen and (max-width:600px) {
  .transition-bot {
  padding: 5vw 0;
}
}
</style>
